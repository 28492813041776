<template>
  <v-chip
    dark
    @click="sendMessageDel(id,tag)"
  >
    登録解除
  </v-chip>
</template>

<script>
  export default {
    props: {
      id: String,
      tag: String,
    },
    methods: {
      sendMessageDel (id, tag) {
        console.log(id)
        console.log(tag)
        var url = 'https://twitter.com/intent/tweet?text=@null ' +
          id +
          '&hashtags=' +
          tag +
          '_del'
        console.log(url)
        window.open(
          url,
          '_blank',
        )
      },
    },
  }
</script>
